<template>
  <div>
    <!-- <div class="vx-row mb-12">
      <vs-button v-on:click="create()" icon="done" color="primary"
        >Create Payment</vs-button
      >
    </div> -->
    <div class="vx-row mb-12 mt-3">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <!-- <vs-prompt
          title="Confirmation"
          color="primary"
          @accept="process"
          @cancel="closePrompt"
          @close="closePrompt"
          :buttons-hidden="false"
          :active.sync="processPrompt"
        >
          <div class="con-exemple-prompt">
            Confirm
            <br />
            Are you sure want to confirm payment with code
            <b>{{ this.selectedData.Code }}</b> ?
          </div>
        </vs-prompt> -->
        <vs-button @click="process" class="mb-3">Confirm</vs-button>
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <vs-th style="width: 15%">
              <vs-checkbox v-model="checkedAll"> Check All </vs-checkbox>
            </vs-th>
          </template>
          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.payment">
              <vs-td
                ><vs-checkbox
                  v-if="tr.BankAccountNumber != '' && tr.Source == 'CIT'"
                  v-on:click="addChecked(tr)"
                  :checked="checked.includes(tr.ID)"
                ></vs-checkbox
              ></vs-td>
              <vs-td>
                <vx-tooltip text="Edit" style="width: fit-content">
                  <vs-button
                    type="line"
                    color="primary"
                    icon-pack="feather"
                    icon="icon-edit"
                    @click="handleEdit(tr)"
                  />
                </vx-tooltip>
              </vs-td>
              <vs-td> {{ tr.CustomerCode }} {{ tr.CustomerName }} </vs-td>
              <vs-td>
                {{ tr.Source }}
              </vs-td>
              <vs-td>
                Code : {{ tr.Code }}
                <br />
                Reference Code : {{ tr.ReferenceCode }}
                <br />
                Payment Type : {{ typePayment[tr.Type] }}
                <br />
                {{ tr.PaymentMethod }} - {{ tr.PaymentName }}
                <br />
                <span v-if="nonCashPayment.includes(tr.PaymentMethod)"
                  >Bank : {{ tr.BankName ? tr.BankName : "-" }}
                  <span v-if="tr.BankName">
                    <br />
                    {{ tr.BankAccountName }} - {{ tr.BankAccountNumber }}</span
                  >
                </span>
              </vs-td>
              <vs-td>
                {{ priceFormat(tr.Amount) }}
              </vs-td>
              <vs-td>
                {{ dateFormat(tr.DatePayment) }}
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <detail :selected="selectedData" @closeDetail="closeDetail" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { dataPayment } from "../../../../services/api/payment";
import create from "./create.vue";
import detail from "./form-edit.vue";
import moment from "moment";
export default {
  props: {
    selected: Object,
    option: Object,
    isReload: Boolean,
    invoiceCode: {
      type: Array,
      default: () => [],
    },
    paymentMethod: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    create,
    detail,
  },
  data() {
    return {
      typePayment: ["", "Trade", "Deposit", "Open Deposit", "Credit Note"],
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "created_at",
      },
      header: [
        {
          text: "Action",
          width: "5%",
        },
        {
          text: "Customer",
          value: "customer_code",
          // width: '5%'
        },
        {
          text: "Source",
        },
        {
          text: "Payment",
          value: "reference_code",
        },
        {
          text: "Amount",
          sortable: false,
        },
        {
          text: "Date",
          value: "date_payment",
        },
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      sourceCIT: "CIT",
      processPrompt: false,
      checkedAll: false,
      checked: [],
      dataChecked: [],
      nonCashPayment: ["Cheque", "Transfer", "Giro", "Bank Transfer"],
    };
  },
  computed: {},
  watch: {
    isReload() {
      if (!this.isReload) {
        return;
      }
      this.reloadData(this.params);
    },
    checkedAll(val) {
      if (val) {
        const temp = [];
        this.responseData.payment.forEach((tr) => {
          if (tr.BankAccountNumber != "") {
            temp.push(tr);
          }
        });
        this.dataChecked = temp;
        this.checked = temp.map((tr) => tr.ID);
      } else {
        this.dataChecked = [];
        this.checked = [];
      }
    },
  },
  mounted() {
    // this.reloadData(this.params);
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    create() {
      this.detail = true;
    },
    // create() {
    //         this.$router.push("/finance/payment/create");

    //     },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },

    handleEdit(tr) {
      console.log(tr, "handleEdit");
      this.selectedData = tr;
      this.detail = true;
    },
    // closePrompt() {
    //   this.processPrompt = false;
    //   this.selectedData = {};
    // },
    process() {
      if (this.checked.length === 0 || this.dataChecked.length === 0) {
        this.$vs.notify({
          title: "Process",
          text: "Please at least select one payment",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        });
        return;
      }

      this.$vs.loading();
      const bulk_data = [];
      this.dataChecked.forEach((tr) => {
        const paymentMethod = tr.PaymentMethod.toLowerCase();
        const status =
          paymentMethod.includes("cash") || paymentMethod.includes("cod")
            ? 6
            : 2; //6 = Done, 2 = Waiting Reconcile
        bulk_data.push({
          id: tr.ID,
          status,
        });

        if (tr.Source == "CIT") {
          if (tr.BankAccountNumber == "") {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Process",
              text: "Please update your bank account: " + tr.Code,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
            });
            return;
          }
        }
      });

      const data = {
        bulk_data,
      };

      this.$http
        .post("/api/v1/payment/cit/confirm/bulk", data)
        .then((r) => {
          if (r.code == 200) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Confirmation",
              text: r.message,
              color: "success",
              iconPack: "feather",
              icon: "icon-check",
              position: "top-right",
            });
            this.checked = [];
            this.dataChecked = [];
            this.reloadData(this.params);
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Confirmation",
              text: r.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Confirmation",
            text: e.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
        });
    },
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    reloadData(params) {
      this.$vs.loading();
      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      const customer_codes =
        this.$store.getters["customerFilter/getSelectedCustomersCodes"];
      this.params = {
        ...params,
        territory_ids,
        customer_codes,
        invoice_codes: this.invoiceCode,
        methods: this.paymentMethod,
      };
      const dataTable = dataPayment(this.params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.payment;
          this.responseData = r.data;
          this.responseData.length = r.data.payment.length;
          this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    addChecked(val) {
      if (this.checked.includes(val.ID)) {
        this.checked.splice(this.checked.indexOf(val.ID), 1);
        this.dataChecked.splice(this.checked.indexOf(val.ID), 1);
      } else {
        this.checked.push(val.ID);
        this.dataChecked.push(val);
      }
      console.log(this.dataChecked, "check");
      console.log(this.checked.length, "leng");
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
